
/* global config */

angular.module('managerApp').factory('preventTemplateCache', function () {
    return {
        'request': function (req) {            
            if (req.url.indexOf('.html') !== -1) {
                req.url = req.url + '?t=' + (config.versionTimestamp);
            }
            return req;
        }
    };
});